import React = require("react");
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { StudentsPage } from "./StudentsPage";
import { ParentsPage } from "./ParentsPage";

export const App = () => {
return (
    <Router>
      <div className='root'>
        <nav>
          <ul>
            <li>
              <Link to="/app">Home</Link>
            </li>
            <li>
              <Link to="/app/students">Students</Link>
            </li>
            <li>
              <Link to="/app/parents">Parents</Link>
            </li>
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/app/students">
            <StudentsPage />
          </Route>
          <Route path="/app/Parents">
            <ParentsPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}