import { DismissalModelTypes } from './models';
import { GetStudentResponse } from './dtos';
export const endpoint = `/api`;
import * as $ from 'jquery';
async function dealWithResponse<T>(responseRaw:Response) : Promise<T>{
    let responseRawText = await responseRaw.text();
    if (!responseRaw.ok){
        let errorMessage = `Request failed with status code of ${responseRaw.status} and text of ${responseRaw.statusText}. \nWith body of ${responseRawText}`;
        let payload: any = {};
        try {
            payload = JSON.parse(responseRawText);
            if (Array.isArray(payload.errors)){
                errorMessage = `Request failed:\n${payload.errors.join('\n')}`;
            }
            if (payload.message){
                errorMessage = `Request failed:\n${payload.message}`;
            }
        }
        catch {
            errorMessage = `Json Cant be parsed \n${errorMessage}`;
        }
        finally {
            throw new Error(errorMessage);
        }
    }

    let response : T = JSON.parse(responseRawText);

    return response;
}
/**
 * Requests that the phone number get signed up/signed in
 *
 * @export
 * @param {string} _phoneNumber
 * @returns
 */
export async function getStudents(): Promise<GetStudentResponse>{
    let responseRaw = await fetch( `${endpoint}/students` , {
        mode: 'no-cors',
        headers: {},
        method: 'GET',
    });
    const results = await new Promise<GetStudentResponse>((resolver) => {
        $.get(`${endpoint}/students`, (data: GetStudentResponse) => {
            resolver(data);
        });
    })
    return results;
}
