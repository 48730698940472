import React = require("react");
import { getStudents } from "../api/endpoints";
import { useState } from "react";
import { GetStudentResponse } from "../api/dtos";
import { mapGetStudentsResponse } from "../api/mappers";
import { StudentModel } from "../api/models";

export const useIsMounted = () => {
    const ref = React.useRef(false)
    const [, setIsMounted] = React.useState(false)
    React.useEffect(() => {
      ref.current = true
      setIsMounted(true)
      return () => (ref.current = false)
    }, [])
    return () => ref.current
  }

export const StudentsPage = () => {
    const [data, setData] = useState<StudentModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sortBy, _setSortBy] = useState<string>("studentId");
    const [sortDirection, setSortDirection] = useState<string>("desc");
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(30);
    const [firstTime, setFirstTime] = useState<boolean>(false);
    const [selected, setSelected] = useState<Array<number>>([]);

    const isMounted = useIsMounted();
    const setSortBy = (_sortBy: string) => {
        if(_sortBy == sortBy && sortDirection == "asc"){
            setSortDirection("desc")
        }
        if(_sortBy != sortBy || sortDirection == "desc"){
            setSortDirection("asc")
        }
        _setSortBy(_sortBy);
        setPage(1);
    }

    

    React.useEffect(() => {
        
        setIsLoading(true);
        getStudents().then((value)=>{
            setData(mapGetStudentsResponse(value));
            setIsLoading(false);
        });
    }, [firstTime]);

    if(!firstTime){
        setFirstTime(true);
    }
    let finalData = data.sort((a: any,b: any) => {
        if(typeof a[sortBy] == "string")
            return a[sortBy].localeCompare(b[sortBy]) * (sortDirection == "desc" ? -1 : 1)
        if(typeof a[sortBy] == "number")
            return (a[sortBy] - b[sortBy])  * (sortDirection == "desc" ? -1 : 1);
        if(typeof a[sortBy] == "undefined")
            return 1  * (sortDirection == "desc" ? -1 : 1);
    });
    let numberOfPages = finalData.length / pageSize;
    let currentPage = Math.min(page, numberOfPages)
    let firstIndex = (currentPage - 1) * pageSize;
    let lastIndex  = (currentPage    ) * pageSize;
    finalData=finalData.filter((item, index, array) => index >= firstIndex && index < lastIndex);
    
    return <div>
                <h1>Students Page</h1>
                
                {isLoading? 
                    <div>Loading...</div> : 
                    <table>
                    <thead>
                        <th></th>
                        <th></th>
                        <th className='orderable' onClick={() => setSortBy("studentId")}>ID</th>
                        <th className='orderable' onClick={() => setSortBy("fullName")}>FULL NAME</th>
                        <th className='orderable' onClick={() => setSortBy("officialIdNo")}>OFFICIAL ID</th>
                        <th>BUS ROUTE(s)</th>
                        <th>CAR GROUP(s)</th>
                    </thead>
                    <tbody>
                    {finalData.map((item) => {
                        return <tr>
                                <td>{selected.find((value) => value == item.studentId) ? 
                                        <div onClick={() => setSelected(selected.filter((value) => value != item.studentId))}>Checked</div> : 
                                        <div onClick={() => setSelected([...selected, item.studentId])}>Unchecked</div>
                                    }
                                 </td>
                                 <td><img src={item.photoUrl} style={{borderRadius:"50%",height:45,width:45}}/></td>
                                 <td>{item.studentId}</td>
                                 <td>{item.fullName}</td>
                                 <td>{item.officialIdNo}</td>
                               </tr>
                    })}
                    </tbody>
                    </table>    
                }

                {currentPage > 1 ? <a href="#" onClick={() => setPage(currentPage - 1)}>Previous</a> : null}
                {<a>{currentPage}</a>}
                {currentPage < numberOfPages ? <a href="#" onClick={() => setPage(currentPage + 1)}>Next</a> : null}
           </div>
}