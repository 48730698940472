import { SignUpRequestResponse, SignUpCodeConfirmationResponse, GetParentProfileResponse, StudentBaseResponseType, SchoolsResponse, SchoolBaseResponseType, LinkStudentResponse, GetBusRoutesResponse, BusRouteBaseType, CarPickupBaseType, GetCarPickupsResponse, CreateStudentStopResponse, NotificationsResponse, NotificationsBaseType, GetCardsResponse, CarGroupBaseType, GetCarGroupsResponse, BusRouteStopBaseType, GetBusRouteStopsResponse, GetParentMessagesResponse, ParentMessagesBaseType, GetStudentResponse } from './dtos';
import { SignUpRequestModel, SignUpCodeConfirmationModel, GetParentProfileModel, StudentModel, SchoolsModel, SchoolModel, LinkStudentModel, BusRouteModel, CarPickupModel, StudentStopModel, NotificationModel, AccountCardModel, CarGroupModel, BusRouteStopModel, ParentMessageModel } from './models';

/**
 * Sign Up Request (Response -> Model)
 *
 * @export
 * @param {SignUpRequestResponse} dto
 * @returns {SignUpRequestModel}
 */
export function mapSignUpRequest(dto:SignUpRequestResponse):SignUpRequestModel{
    return {
        success: typeof dto.status === 'string' && typeof dto.verification_id === 'number',
        verificationId: dto.verification_id,
    };
}

/**
 * Sign Up Code Confirmation (Response -> Model)
 *
 * @export
 * @param {SignUpCodeConfirmationResponse} dto
 * @returns {SignUpCodeConfirmationModel}
 */
export function mapSignUpCodeConfirmation(dto:SignUpCodeConfirmationResponse) : SignUpCodeConfirmationModel{
    return {
        access_token:dto.access_token,
        success: dto.granted,
        parentId: dto.id,
    };
}


export function mapGetParentProfile(dto:GetParentProfileResponse) : GetParentProfileModel{
    
    const students:StudentBaseResponseType[] = [];
    const school:SchoolBaseResponseType[] = [];
    dto.included?.forEach((include) => {
        if (include.type === 'school'){
            school.push(include);
        }
        if (include.type === 'student'){
            students.push(include);
        }
    });

    return {
        phoneNumber: dto.data.attributes.phone_number,
        parentId: parseInt(dto.data.id, 10),
        isVerified: dto.data.attributes.isVerified,
        students:  students.map((student) => {
            return mapStudentBase(student);
        }) || [],
    };
}
export function mapStudentBase(dto:StudentBaseResponseType) : StudentModel{
    return {
        fullName: dto.attributes.full_name,
        officialIdNo: dto.attributes.official_id_no,
        photoUrl: dto.attributes.photo_url,
        smsEnabled: dto.attributes.sms_enabled,
        studentId: parseInt(dto.id, 10),
        activeDismissal: dto.attributes.active_student_stop === '' ? 'Unknown' : dto.attributes.active_student_stop,
        activeDismissalApproved: (dto.attributes.active_student_stop === '') ? true : dto.attributes.active_student_stop_approved,
        schoolId: parseInt(dto.relationships.school.links.related.replace(/[^\d]/g, ''), 10),
    };
}
export function mapSchools(dto:SchoolsResponse) : SchoolsModel{
    return {
        schools: dto.data.map((school) => mapSchoolBase(school)),
    };
}
export function mapSchoolBase(dto:SchoolBaseResponseType) : SchoolModel{
    return {
        id: parseInt(dto.id, 10),
        dispatch_emergency: dto.attributes.dispatch_emergency,
        latitude: parseInt(dto.attributes.latitude, 10),
        longitude: parseInt(dto.attributes.longitude, 10),
        name: dto.attributes.name,
    };
}

export function mapLinkStudent(dto:LinkStudentResponse) : LinkStudentModel{

    return {
        message: dto.message,
        success: dto.status === 'success',
    };
}
export function mapBusRoute(dto:BusRouteBaseType) : BusRouteModel{
    return {
        id: parseInt(dto.id, 10),
        name: dto.attributes.name,
    };
}
export function mapBusRoutes(dto:GetBusRoutesResponse) : BusRouteModel[]{
    return dto.data.map((busRoute) => mapBusRoute(busRoute));
}
export function mapCarPickup(dto:CarPickupBaseType) : CarPickupModel{
    return {
        id: parseInt(dto.id, 10),
        name: dto.attributes.name,
    };
}
export function mapCarPickups(dto:GetCarPickupsResponse) : CarPickupModel[]{
    return dto.data.map((busRoute) => mapCarPickup(busRoute));
}
export function mapCarGroup(dto:CarGroupBaseType) : CarGroupModel{
    return {
        id: parseInt(dto.id, 10),
        name: dto.attributes.name,
    };
}
export function mapCarGroups(dto:GetCarGroupsResponse) : CarGroupModel[]{
    return dto.data.map((carGroup) => mapCarGroup(carGroup));
}
export function mapBusRouteStop(dto:BusRouteStopBaseType) : BusRouteStopModel{
    return {
        id: parseInt(dto.id, 10),
        name: dto.attributes.name,
    };
}
export function mapBusRouteStops(dto:GetBusRouteStopsResponse) : BusRouteStopModel[]{
    return dto.data.map((busRoute) => mapBusRouteStop(busRoute));
}
export function mapCreateStudentStopResponse(dto:CreateStudentStopResponse) : StudentStopModel{
    return {
        busRouteStopId: dto.data.attributes.bus_route_stop_id,
        id: parseInt(dto.data.id,10),
        endDate: dto.data.attributes.end ? new Date(dto.data.attributes.end) : undefined,
        startDate: dto.data.attributes.start ? new Date(dto.data.attributes.start) : undefined,
        studentId: dto.data.attributes.student_id,
        type: dto.data.attributes.type === '' ? undefined : dto.data.attributes.type,
    };
}
export function mapNotificationsResponse(dto: NotificationsResponse) : {notifications: NotificationModel[], students: StudentModel[]} {
    return {
        notifications: dto.data.map((notification) => mapNotification(notification)),
        students: dto.included ? dto.included.map((student) => mapStudentBase(student)) : [],
    };
}
export function mapNotification(dto: NotificationsBaseType) : NotificationModel {
    return {
        message: dto.attributes.message,
        photoUrl: dto.attributes.student_photo_url,
        sentTime: dto.attributes.local_time,
        studentId: dto.attributes.student_id,
        studentName: dto.attributes.student_full_name,
        sentDate: new Date(dto.attributes.inserted_at),
    };
}
export function mapAccountCards(dto: GetCardsResponse) : AccountCardModel[] {
    return dto.map((card) => {
        return {
            cardBrand: card.brand,
            expiration: `${card.exp_month}/${card.exp_year}`,
            last4: card.last4,
        };
    });
}

export function mapParentMessagesResponse(dto: GetParentMessagesResponse) : {messages: ParentMessageModel[]} {
    return {
        messages: dto.data.map((message) => mapParentMessage(message)),
    };
}
export function mapParentMessage(dto: ParentMessagesBaseType) : ParentMessageModel {
    return {
        message: dto.attributes.message,
        createdDate: new Date(dto.attributes.inserted_at),
        type: dto.attributes.type,
    };
}
export function mapGetStudentsResponse(dto:GetStudentResponse) : StudentModel[]{
    return dto.data.map((item) => mapStudentBase(item));
}